// src/pages/Services.js
import React from "react";
import { Container, Typography, Box, Tabs, Tab } from "@mui/material";
import { pink } from "@mui/material/colors";

import Grid from "@mui/material/Grid2";
import dataJson from "./data.json";
import ServiceList from "../components/List";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    wrapped: true,
  };
}

const Services = () => {
  const [value, setValue] = React.useState(0);

  const imagingList1 = [];
  const imagingList2 = [];
  const neuroList = [];
  const labTestList1 = [];
  const labTestList2 = [];
  const cultureTestList = [];
  const cancerTestList = [];
  const sequencingTestList = [];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Our Services
      </Typography>
      <Typography variant="h4" align="center" sx={{ my: 2 }}>
        Explore Our Range of Services
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Medical and Surgical Services" {...a11yProps(0)} />
            <Tab label="Imaging" {...a11yProps(1)} />
            <Tab label="Electrodiagnostic Testing" {...a11yProps(2)} />
            <Tab label="Metabolic Testing" {...a11yProps(3)} />
            <Tab label="Microbiology Testing" {...a11yProps(4)} />
            <Tab label="Pathology Testing" {...a11yProps(5)} />
            <Tab label="Genomic Testing" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <img src={dataJson["services"]["surgery"]} />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.procedureList1} />
              </Grid>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.procedureList2} />
              </Grid>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.procedureList3} />
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid size={4} sx={{ mt: 3 }}>
                <img src={dataJson["services"]["imaging"]} />
              </Grid>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.imagingList1} />
              </Grid>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.imagingList2} />
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid size={8} sx={{ mt: 3 }}>
                <img src={dataJson["services"]["electroDiagnostic"]} />
              </Grid>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.neuroList} />
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid size={6}>
                <img
                  src={dataJson["services"]["metabolicTesting"]}
                  height={500}
                />
              </Grid>
              <Grid size={3}>
                <ServiceList data={dataJson.servicesList.labTestList1} />
              </Grid>
              <Grid size={3}>
                <ServiceList data={dataJson.servicesList.labTestList2} />
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid size={8} sx={{ mt: 3 }}>
                <img src={dataJson["services"]["microbiologyTesting"]} />
              </Grid>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.cultureTestList} />
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid size={8} sx={{ mt: 3 }}>
                <img src={dataJson["services"]["pathologyTesting"]} />
              </Grid>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.cancerTestList} />
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid size={8} sx={{ mt: 3 }}>
                <img src={dataJson["services"]["genomicTesting"]} />
              </Grid>
              <Grid size={4}>
                <ServiceList data={dataJson.servicesList.sequencingTestList} />
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
      </Box>

      {/* <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Medical and Surgical Services
      </Typography>
      <Typography>
        Medical and Surgical Services Innovative Healthcare Solutions using
        Artificial Intelligence, Real Time Monitoring, Internet of Things, Data
        Acquisition & Analysis, Nex Gen 5G enabled delivery of Medical &
        Surgical Solutions, Nex Gen Tools, Healthcare Mapping, Minimally
        Invasive Therapeutics, etc
      </Typography> */}

      {/* <Grid container spacing={2}>
        {procedureList.map((item, index) => (
          <Grid item lg={3} key={index}>
            <ListItem>
              <ListItemText primary={item} sx={{ textAlign: "left" }} />
            </ListItem>
          </Grid>
        ))}
      </Grid> */}

      {/* <List>
        {procedureList.map((procedure, index) => (
          <ListItem key={index}>
            <ListItemText primary={procedure} />
          </ListItem>
        ))}
      </List> */}
      {/* <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Diagnostics & Imaging
      </Typography>
      <Typography>
        Usage of Artificial Intelligence, Machine Vision, Machine Learning,
        Internet of Things Nex Gen Processes and Systems to obtain optimal
        outcomes
      </Typography>
      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Imaging
      </Typography>

      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Electrodiagnostic Testing
      </Typography>

      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Metabolic Testing
      </Typography>

      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Microbiology Testing
      </Typography>

      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Pathology Testing
      </Typography>
      <List>
        {cancerTestList.map((test, index) => (
          <ListItem key={index}>
            <ListItemText primary={test} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Genomic Testing
      </Typography>

      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Genomics
      </Typography>
      <Typography>
        Cutting edge Genomics, Transcriptomics, Proteomics, Engineered
        Solutions, Synthetic Biology, Process Biology, etc to craft, deliver,
        monitor and optimize cell, organ, organism and society level outcomes
      </Typography>
      <Typography variant="h5" align="center" sx={{ my: 2 }}>
        Biologics
      </Typography>
      <Typography>
        Using traditional and frontier technologies like Metabolomics,
        Cellulomics and Proteomics to redefine healthcare regarding diagnostic,
        monitoring and treatment modes
      </Typography> */}
    </Container>
  );
};

export default Services;
