// src/pages/ContactUs.js
import React from "react";
import { Container, Typography, TextField, Button } from "@mui/material";

const ContactUs = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <TextField label="Name" variant="outlined" fullWidth margin="normal" />
      <TextField label="Email" variant="outlined" fullWidth margin="normal" />
      <TextField
        label="Message"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <Button variant="contained" color="primary" style={{ marginTop: "16px" }}>
        Submit
      </Button>
      <Typography sx={{ mt: 2 }}>
        You can also write to us at{" "}
        <a href="mailto:americasdocs@gmail.com">americasdocs@gmail.com</a>
      </Typography>
    </Container>
  );
};

export default ContactUs;
