import React from "react";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/Circle"; // Example icon

function ServiceList({ data }) {
  return (
    <List>
      {data.map((procedure, index) => (
        <ListItem key={index} disableGutters>
          <ListItemIcon>
            <CheckCircleIcon sx={{ fontSize: 10 }} />
          </ListItemIcon>
          <ListItemText primary={procedure} />
        </ListItem>
      ))}
    </List>
  );
}

export default ServiceList;
