// src/pages/Home.js
import React from "react";
import { Container, Typography } from "@mui/material";

const Home = () => {
  return (
    <Container className="home">
      <Typography variant="h4" gutterBottom className="quote">
        Welcome to Ishwar Care
      </Typography>
      <br />
      <br />
      <Typography>
        Ishwar Care intends to help in the provision of State of the Art Care in
        Musculoskeletal Medicine and Pain Management using the best
        technologies, paradigms and tools available in a team centric approach.
      </Typography>
      <br />
      <Typography>
        We value critical thinking, innovation and strongly believe in seamless
        collaboration across multiple disciplines and fields of Medicine &amp;
        Surgery. We are strongly focused on the Future and harness Artificial
        Intelligence, Deep Tech, Internet of Things, Robotics, Process
        Engineering, etc. in relentlessly pursuing real world solutions.
      </Typography>
      <br />
      <Typography>
        We seamlessly integrate Genomics, Cellulomics, Stem Cell Therapeutics,
        Robotics, Deep Tech, Minimally Invasive Therapeutics, etc. to drive our
        quest for world class outcomes. We utilize the best materials and
        service providers in the areas we serve. Our providers are well trained,
        utilize world class care paradigms and are conversant with the latest
        medical research worldwide.
      </Typography>
      <br />
      <Typography>
        Our nursing and clinical staff is well trained, effective and caring. We
        value diversity, inquisitiveness, inclusivity and strongly believe in
        patient and community involvement. We believe in sharing knowledge and
        advocate self empowerment for achieving optimal outcomes.
      </Typography>
      <br />
      <br />
      <div className="quote-container">
        <Typography variant="h4" gutterTop className="quote tagline">
          World Class Care For All
        </Typography>
        <img
          className="home-image"
          src="https://asianheartinstitute.org/wp-content/uploads/2023/12/home-banner.webp"
        />
      </div>
    </Container>
  );
};

export default Home;
