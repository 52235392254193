// src/pages/AboutUs.js
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

const AboutUs = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={3}>
            <img
              src="https://img.pikbest.com/png-images/20240731/a-man-doctor-outline-cartoon-vector-illustration_10691388.png!sw800"
              width="200px"
            />
          </Grid>
          <Grid size={9}>
            <Typography
              variant="body1"
              sx={{
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              <strong className="doc-name">Dr K Mahesh Reddy, MD </strong> was
              accepted into the top medical schools in India at the relatively
              young age of 17. He completed his medical training and graduated
              from Gandhi Medical College in Hyderabad, India. He went on to
              complete his Residency in Physical Medicine and Rehabilitation
              from Temple University Hospital, Temple University in
              Philadelphia, Pennsylvania, United States of America. He completed
              his Fellowship in Pain Management, Department of Anesthesia from
              University Hospitals of Cleveland, Case Western Reserve University
              in Cleveland, Ohio, United States of America.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Typography sx={{ mt: 2 }}>
        He has been accepted at the most prestigious institutions both in India
        and United States of America. He has authored multiple research articles
        in clinical medicine with many firsts to his credit. He has presented
        extensively both in the national and international arenas. He is a
        published author with multiple books in varied fields of medicine.
      </Typography>
      <Typography sx={{ mt: 4 }}>
        He is a passionate believer in the delivery of World Class Care For All.
        He believes in sharing knowledge and best practices while empowering
        everyone to be their best. He is a thought leader in his field of
        expertise and his interests encompass Precision Medicine, Minimally
        Invasive Surgery, Deep Tech and Genomics.
      </Typography>
    </Container>
  );
};

export default AboutUs;
